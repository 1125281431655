import moment from 'moment';

/**
 * Enum for types of SAF-T
 * @enum {string}
 * @readonly
 */
const SAFT_TYPE = {
  ALL: 'all',
  GUIDES: 'guides',
};

/**
 * Enum for types of period
 * @enum {string}
 * @readonly
 */
const PERIOD = {
  ANNUAL: 'year',
  MONTH: 'month',
  WEEKLY: 'week',
  DAILY: 'day',
};

/**
 * Return initial state
 *
 * @param {*} intl - Internacionalization
 * @returns
 */
const getInitialState = (intl) => {
  return {
    saftType: SAFT_TYPE.ALL,
    period: PERIOD.MONTH,
    year: moment().year(),
    month: moment().month(),
    week: getWeeksInMonth(
      intl,
      moment().year(),
      moment().month(),
      intl.formatMessage({ id: `exportSAFTMonth${moment().month()}` })
    )[0].value,
    day: 1,
  };
};

/**
 * Return months internationalized
 *
 * @param {*} intl - Internacionalization
 * @returns
 */
const getMonths = (intl) => {
  const months = [...Array(12)];

  months.forEach((_, i) => {
    months[i] = {
      key: `exportSAFTMonth${i}`,
      value: i,
    };
  });

  return months;
};

/**
 * @param {int} The year, not zero based, required to account for leap years
 * @param {int} The month number, 0 based
 * @return {Array<{key: int, value: int}>} List with objects for each day of the month
 */
const getDaysInMonth = (year, month) => {
  const days = [];
  const startOfMonth = moment([year, month]);

  const daysInThisMonth = startOfMonth.daysInMonth();

  for (let day = 1; day <= daysInThisMonth; day++) {
    days.push({
      key: day,
      value: day,
    });
  }

  return days;
};

function getWeeksInMonth(intl, year, month, monthName) {
  var weeks = [];
  var monthEnd = moment().year(year).month(month).endOf('month');
  var weekStart = moment().year(year).month(month).date(1);
  var weekEnd = moment(weekStart).endOf('isoWeek');
  var weeksSplitOperationFlag = true;

  while (weeksSplitOperationFlag) {
    if (month === weekEnd.month()) {
      // Still on current month
      if (weekStart.format('D') === weekEnd.format('D')) {
        // Only a Sunday
        weeks.push({
          key: translateDateInterval(intl, [weekStart.format('D')], monthName),
          value:
            '' +
            year +
            weekStart.format('/MM/DD') +
            '-' +
            year +
            weekStart.format('/MM/DD'),
        });
      } else {
        // normal weeks
        weeks.push({
          key: translateDateInterval(
            intl,
            [weekStart.format('D'), weekEnd.format('D')],
            monthName
          ),
          value: '' + weekStart.format('D'),
        });
      }
    } else {
      // weekEnd belongs to another month
      if (monthEnd.format('LL') === weekStart.format('LL')) {
        // last day of the month
        weeks.push({
          key: translateDateInterval(intl, [weekStart.format('D')], monthName),
          value: '' + weekStart.format('D'),
        });
      } else if (weekStart.month() !== month) {
        // Previous week ended on the last day of the month (Sunday)
        break;
      } else {
        // normal week, retrieving only the days that belong to the selected month
        weeks.push({
          key: translateDateInterval(
            intl,
            [weekStart.format('D'), monthEnd.format('D')],
            monthName
          ),
          value: '' + weekStart.format('D'),
        });
      }

      weeksSplitOperationFlag = false;
    }

    weekStart = weekEnd.add(1, 'days');
    weekEnd = moment(weekStart).endOf('isoWeek'); // Start weeks on Monday
  }

  return weeks;
}

function translateDateInterval(intl, dateRange, monthName) {
  if (dateRange.length === 1) {
    return intl.formatMessage(
      { id: 'exportSAFTWeekOptionsSingle' },
      { start: dateRange[0], month: monthName }
    );
  } else {
    return intl.formatMessage(
      { id: 'exportSAFTWeekOptionsDouble' },
      { start: dateRange[0], end: dateRange[1], month: monthName }
    );
  }
}

export const ExportSaftHelper = {
  getInitialState,
  getMonths,
  getDaysInMonth,
  getWeeksInMonth,
  PERIOD,
};
