import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '../../../atoms/buttons/Button';

import { ReactComponent as IXLogo } from '../../../../assets/imgs/ix-logo-horiz.svg';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import Span from '../../../atoms/spans/Span';
import Anchor from '../../../atoms/anchors/Anchor';
import { buildPath } from '../../../organisms/containers/util/pathHelper';
import { REGISTER_PATH } from '../../../../constants';

import Alert from '../../../molecules/alerts/Alert';
import { AuthenticationService } from '../../../../services/AuthenticationService';
import InputPassword from '../../../molecules/inputs/InputPassword';
import SuccessModal from '../../feedback/modal/SuccessModal';
import { useHistory } from 'react-router-dom';
import StorageService from '../../../../services/StorageService';
import { STORAGE_KEYS } from '../../../../constants/storage';
import { LOGIN_URL } from '../../../../constants/url';
import { validateEqualValues } from '../../../../helpers/validateFormatHelper';

const NewPassword = ({ form, setForm, intl, ...props }) => {
  const history = useHistory();
  const [messageError, setMessageError] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);

  const handleInput = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const isRequiredFieldsFilled = () => {
    return form.password !== '' && form.password_confirmation !== '';
  };

  const onSubmit = async () => {
    setShowSuccessModal(false);
    setMessageError('');

    const token = history.location?.pathname?.split('/')[3];

    if (isRequiredFieldsFilled()) {
      const Storage = new StorageService();

      const response = await AuthenticationService.updatePassword({
        user: {
          ...form,
          email: JSON.parse(Storage.getItem(STORAGE_KEYS.EMAIL))?.email,
          reset_code: token,
        },
      });
      if (!response.error) {
        Storage.clearItem(STORAGE_KEYS.EMAIL);
        setShowSuccessModal(true);
        return;
      }
      setMessageError(response.error);
    }
  };

  /**
   * Redirect to login page
   */
  const onCloseModal = () => {
    history.push(LOGIN_URL);
  };

  /**
   * Verify if passwords match
   * @param {string} login
   */
  const checkMatchPassword = (password) => {
    setIsPasswordInvalid(validateEqualValues(form.password, password));
  };

  return (
    <div className='row --login-wrapper'>
      <div className='col-lg-6 col-xs-12 --login-wrapper-image'>
        <div className='row'>
          <div className='col-12 m-2'>
            <IXLogo className='--logo' />
            <Paragraph className='text-header h2 mt-2'>
              <FormattedMessage
                id='loginDescription'
                values={{
                  r: (...chunks) => (
                    <Span className='font-regular'>
                      {chunks}
                      <br />
                    </Span>
                  ),
                  b: (...chunks) => <b>{chunks}</b>,
                }}
              />
            </Paragraph>
          </div>

          <div className='col-12 mt-4 --login-wrapper-links'>
            <Anchor href={buildPath(REGISTER_PATH().LOGIN)}>
              {intl.messages['loginTitle']} |
            </Anchor>
            <Anchor href={buildPath(REGISTER_PATH().CREATE_ACCOUNT)}>
              {' '}
              {intl.messages['createAccountLink']}
            </Anchor>
          </div>
        </div>
      </div>

      <div className='col-lg-6 col-xs-12 pb-4 --login-form'>
        <div className='column form-container'>
          <div className='col-10 mt-4'>
            <Paragraph className='text-header h2 mb-2'>
              {intl.messages['newPasswordTitle']}
            </Paragraph>
            <Paragraph className='text-paragraph mb-2'>
              {intl.messages['newPasswordDescription']}
            </Paragraph>

            {messageError && (
              <Alert
                id='request_alert_error'
                alertType={`alert-error --icon`}
                iconClassName='fas fa-exclamation-circle'
              >
                {messageError}
              </Alert>
            )}
            <label className='text-label mt-3'>
              {intl.messages['password']}:
            </label>

            <InputPassword
              id='password'
              value={form.password}
              onChange={(e) => {
                handleInput('password', e.target.value);

                checkMatchPassword(e.target.value);
              }}
              className='input-icon --right'
              inputClassName='d-block'
            />

            <label className='text-label mt-3'>
              {intl.messages['confirmPassword']}:
            </label>
            <InputPassword
              id='password_confirmation'
              className='input-icon --right'
              inputClassName='d-block'
              value={form.password_confirmation}
              onChange={(e) => {
                handleInput('password_confirmation', e.target.value);
                checkMatchPassword(e.target.value);
              }}
              hasError={isPasswordInvalid}
            />
          </div>

          <div className='col-10 mt-4'>
            <Button
              id='reset_password_submit'
              className='button button-primary d-block'
              label='send'
              onClick={onSubmit}
            />
          </div>

          {showSuccessModal && (
            <SuccessModal
              messageKey='dataSavedSuccess'
              onClose={onCloseModal}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default injectIntl(NewPassword);
