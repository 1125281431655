import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Modal from '../../../organisms/modals/Modal';
import Button from '../../../atoms/buttons/Button';

const HandleCallResultModal = (props) => {
  const { isLoading, succeeded, message, onClose, showCloseButton } = props;
  const successIcon = 'fa fa-check';
  const errorIcon = 'fa fa-times error';

  return (
    <Modal
      isLoading={isLoading}
      iconName={succeeded ? successIcon : errorIcon}
      className='handle-call-result-modal'
    >
      <div className='d-flex flex-column px-4 pb-4'>
        <p className='text-align-center text-paragraph bold my-4'>
          {props.intl.messages[message] || message}
        </p>

        {!isLoading && showCloseButton && (
          <div className='d-flex w-100'>
            <Button
              id='closeButton'
              className='button button-primary ms-auto'
              onClick={() => onClose()}
            >
              <FormattedMessage id='closeAction' />
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default injectIntl(HandleCallResultModal);
