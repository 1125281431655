import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { errorKeys } from '../../../../constants/documentCustomization';
import { AppContext } from '../../../../contexts/AppContext';
import { ContactService } from '../../../../services';
import { RequestHandelingAlert } from '../../../organisms/alerts/RequestHandelingAlert';
import Menu, { MENU_CATEGORY } from '../../../organisms/menu/Menu';
import {
  validatePassword,
  validateEqualValues,
} from '../../../../helpers/validateFormatHelper';
import SuccessModal from '../../feedback/modal/SuccessModal';
import { StateHelper } from '../../helper/state';
import Form from './form/Form';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../layouts/redux/hooks';
import { changeModalVisibilityReducer } from '../../../../layouts/redux/modal-fallbacks';
import { MODAL_TYPES } from '../../feedback/HandleModalFallbacks';
import { useParams } from 'react-router-dom';
import { AuthenticationService } from '../../../../services/AuthenticationService';

const MyProfileLayout = (props) => {
  const { appState } = useContext(AppContext);
  const fallbackState = useAppSelector((state) => state.modalFallbacks);
  const params = useParams();
  const dispatch = useAppDispatch();
  const [fields, setFields] = useState({
    password: '',
    password_confirmation: '',
    actual_password: '',
  });
  const [phoneCountryCodes, setPhoneCountryCodes] = useState([]);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSendWithSuccess, setIsSendWithSuccess] = useState(false);
  const [requestErrors, setRequestErrors] = useState('');

  const alertContainerRef = useRef(null);

  const onChangeField = (key, value) => {
    setFields({
      ...fields,
      [key]: value,
    });
  };

  const handlePasswordChangeRequest = async (dataForm) => {
    clearErrors();

    const response = await AuthenticationService.login({
      login: dataForm.email,
      password: dataForm.actual_password,
    });

    if (response.error) {
      handleErrors(response.error);
      return;
    }

    dispatch(
      changeModalVisibilityReducer({
        type: MODAL_TYPES.TWO_FACTOR_AUTHENTICATION,
        showFallback: true,
        isFallbackApproved: false,
      })
    );
  };

  const handleRequest = async (dataForm) => {
    setIsSubmitted(true);
    clearErrors();

    const response = await ContactService.updateUserById(
      params.userId,
      dataForm
    );

    if (response.error) {
      handleErrors(response.error);
      return;
    }

    setIsSendWithSuccess(true);

    clearPasswordFields();
    setTimeout(() => {
      setIsSendWithSuccess(false);
    }, 2000);
  };

  /**
   * Call service to create or update contact
   *
   * @async
   * @returns {void}
   */
  const handleSubmit = async () => {
    const { password, password_confirmation, actual_password, ...restFields } =
      fields;

    let dataForm =
      actual_password !== '' ? { ...restFields, actual_password } : restFields;

    if (isPasswordFieldsFilled() && isPasswordValid() && isPasswordMatching()) {
      return handlePasswordChangeRequest(dataForm);
    } else if (isPasswordFieldsEmpty()) {
      return handleRequest(dataForm);
    } else {
      handleErrors(props.intl.messages['passwordSubmitError']);
      return;
    }
  };

  const isRequiredFieldsFilled = () => {
    if (
      (isPasswordFieldsFilled() && isPasswordValid() && isPasswordMatching()) ||
      isPasswordFieldsEmpty()
    ) {
      return true;
    } else if (fields.name === '' || fields.email === '') {
      return true;
    } else {
      return false;
    }
  };

  const isPasswordFieldsFilled = () => {
    return (
      fields.password && fields.password_confirmation && fields.actual_password
    );
  };

  const isPasswordFieldsEmpty = () => {
    return (
      !fields.password &&
      !fields.password_confirmation &&
      !fields.actual_password
    );
  };

  const isPasswordValid = () => {
    if (!fields.password) {
      return true;
    } else {
      return validatePassword(fields.password);
    }
  };

  const isPasswordMatching = () => {
    if (!fields.password || !fields.password_confirmation) {
      return true;
    }
    return validateEqualValues(fields.password, fields.password_confirmation);
  };

  const handleErrors = (message) => {
    setRequestErrors(message);
    alertContainerRef.current && alertContainerRef.current.scrollIntoView();
  };

  const clearErrors = () => {
    setRequestErrors('');
  };

  const clearPasswordFields = () => {
    let newFields = { ...fields };

    newFields.password = '';
    newFields.password_confirmation = '';
    newFields.actual_password = '';

    setFields({ ...newFields });
  };

  useMemo(async () => {
    setPhoneCountryCodes(
      await StateHelper.fetchPhoneCountries(appState.accountId, () =>
        handleErrors(errorKeys.fetchError)
      )
    );

    const { user } = await ContactService.getUserById(params.userId);
    setFields(user || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.accountId]);

  useEffect(() => {
    if (fallbackState.isFallbackApproved) {
      handleRequest(fields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fallbackState.isFallbackApproved]);

  return (
    <div id='main-content' className='container --contacts'>
      <div className='row justify-content-center'>
        <div className='col-lg-9'>
          <div className='block-grid'>
            <div className='row grid-block two-cols-container'></div>
            <div className='row grid-block form-container'>
              {/* Header */}
              <div
                ref={alertContainerRef}
                className='width-100 grid-block page-header-block'
              >
                <div className='text-header h2 text-align-left'>
                  {props.intl.messages['myProfileTitle']}
                </div>

                {requestErrors.length > 0 && (
                  <RequestHandelingAlert error={requestErrors} />
                )}
              </div>

              <div className='width-100'>
                <Form
                  fields={fields}
                  phoneCountryCodes={phoneCountryCodes}
                  onChangeField={onChangeField}
                  handleSubmit={handleSubmit}
                  isSubmitDisabled={!isRequiredFieldsFilled()}
                  isPasswordMatching={isPasswordMatching()}
                  isPasswordValid={isPasswordValid()}
                />
              </div>

              {isSubmitted && (
                <SuccessModal
                  isLoading={!isSendWithSuccess}
                  messageKey={'contactUpdated'}
                  onClose={() => setIsSubmitted(false)}
                />
              )}
            </div>
          </div>
        </div>
        <div className='col-lg-3 mt-5'>
          <Menu {...props} activeCategory={MENU_CATEGORY.ACCOUNT} />
        </div>
      </div>
    </div>
  );
};

export default injectIntl(MyProfileLayout);
