import { injectIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import Select from '../../../atoms/selects/Select';
import { bankDetails } from '../../../templates/helper/documentInformationHelper';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import Input from '../../../atoms/inputs/Input';
import { computeBankType } from '../util/selectsHelper';
import { computeInputValue } from '../util/inputsHelper';

const BankData = (props) => {
  const {
    bankDetailsType1,
    bankDetailsData1,
    bankDetailsType2,
    bankDetailsData2,
    bankDetailsType3,
    bankDetailsData3,
  } = props.state;

  const selectOption1 = computeBankType(bankDetailsType1);
  const selectOption2 = computeBankType(bankDetailsType2);
  const selectOption3 = computeBankType(bankDetailsType3);

  const [input1, setInput1] = useState(computeInputValue(bankDetailsType1));
  const [input2, setInput2] = useState(computeInputValue(bankDetailsType2));
  const [input3, setInput3] = useState(computeInputValue(bankDetailsType3));

  const [input1Error, setInput1Error] = useState(false);
  const [input2Error, setInput2Error] = useState(false);
  const [input3Error, setInput3Error] = useState(false);

  const selectSetInputError = {
    bankDetailsType1: setInput1Error,
    bankDetailsType2: setInput2Error,
    bankDetailsType3: setInput3Error,
  };

  const selectToBankData = {
    bankDetailsType1: bankDetailsData1,
    bankDetailsType2: bankDetailsData2,
    bankDetailsType3: bankDetailsData3,
  };

  const setHandler = (key, bankDataNumber, value) => {
    props.setUserChangedInput(true);
    if (value === 'select') {
      clearInput(key);
    } else {
      props.handler({
        ...props.state,
        [key]: value,
      });
    }

    if (key.includes('bankDetailsType')) {
      selectSetInputError[key](false);

      if (selectToBankData[key] === '' && value !== 'select' && value !== '') {
        props.addInputErrorHandler(bankDataNumber);
      } else {
        props.removeInputErrorHandler(bankDataNumber);
      }
    }
  };

  useEffect(() => {
    setInput1(computeInputValue(bankDetailsType1));
    setInput2(computeInputValue(bankDetailsType2));
    setInput3(computeInputValue(bankDetailsType3));
  }, [bankDetailsType1, bankDetailsType2, bankDetailsType3]);

  const shouldBeDisabled = (type, inputValue) =>
    type === 'select' || (type === 'other' && inputValue === '');

  const shouldShowError = (type, data, bankDataNumber) => {
    if (!['', 'select'].includes(type) && !data) {
      props.addInputErrorHandler(bankDataNumber);
      return true;
    } else {
      props.removeInputErrorHandler(bankDataNumber);
      return false;
    }
  };

  const clearInput = (key) => {
    const index = key.split('bankDetailsType')[1];

    props.handler({
      ...props.state,
      [key]: '',
      [`bankDetailsData${index}`]: '',
    });
  };

  return (
    <div className='row px-0 mx-0 content-block bank-data-block'>
      <div className='text-header h3 section-title'>
        {props.intl.messages['bankData']}
      </div>
      <Paragraph className='text-paragraph px-0'>
        {props.intl.messages['bankDataDescription']}
      </Paragraph>
      <div className='form-container px-0'>
        <div className='form-row dependent row px-0 mx-0'>
          <div className='col-sm-3 ps-0'>
            <Select
              id='bank_type_select_1'
              value={selectOption1}
              options={bankDetails()}
              onChange={(e) =>
                setHandler('bankDetailsType1', 'bankData1', e.target.value)
              }
            />
            {selectOption1 === 'other' && (
              <div className='other-input'>
                <Input
                  id={'bank_type_input_1'}
                  type='text'
                  value={input1}
                  onChange={(e) => setInput1(e.target.value)}
                  onBlur={() =>
                    setHandler('bankDetailsType1', 'bankData1', input1)
                  }
                  controlled
                />
              </div>
            )}
          </div>
          <div className='col-sm-9 pe-0'>
            <Input
              id='bank_type_data_input_1'
              type='text'
              controlled
              value={bankDetailsData1}
              onChange={(e) =>
                setHandler('bankDetailsData1', 'bankData1', e.target.value)
              }
              onBlur={() =>
                setInput1Error(
                  shouldShowError(
                    bankDetailsType1,
                    bankDetailsData1,
                    'bankData1'
                  )
                )
              }
              disabled={shouldBeDisabled(selectOption1, input1)}
              hasError={input1Error}
            />
          </div>
        </div>
        <div className='form-row dependent row px-0 mx-0'>
          <div className='col-sm-3 ps-0'>
            <Select
              id='bank_type_select_2'
              value={selectOption2}
              options={bankDetails()}
              onChange={(e) =>
                setHandler('bankDetailsType2', 'bankData2', e.target.value)
              }
            />
            {selectOption2 === 'other' && (
              <div className='other-input'>
                <Input
                  id={'bank_type_input_2'}
                  type='text'
                  value={input2}
                  onChange={(e) => setInput2(e.target.value)}
                  onBlur={() =>
                    setHandler('bankDetailsType2', 'bankData2', input2)
                  }
                  controlled
                />
              </div>
            )}
          </div>
          <div className='col-sm-9 pe-0'>
            <Input
              id='bank_type_data_input_2'
              type='text'
              controlled
              value={bankDetailsData2}
              onChange={(e) =>
                setHandler('bankDetailsData2', 'bankData2', e.target.value)
              }
              onBlur={() =>
                setInput2Error(
                  shouldShowError(
                    bankDetailsType2,
                    bankDetailsData2,
                    'bankData2'
                  )
                )
              }
              disabled={shouldBeDisabled(selectOption2, input2)}
              hasError={input2Error}
            />
          </div>
        </div>
        <div className='form-row dependent row px-0 mx-0'>
          <div className='col-sm-3 ps-0'>
            <Select
              id='bank_type_select_3'
              value={selectOption3}
              options={bankDetails()}
              onChange={(e) =>
                setHandler('bankDetailsType3', 'bankData3', e.target.value)
              }
            />
            {selectOption3 === 'other' && (
              <div className='other-input'>
                <Input
                  id={'bank_type_input_3'}
                  type='text'
                  value={input3}
                  onChange={(e) => setInput3(e.target.value)}
                  onBlur={() =>
                    setHandler('bankDetailsType3', 'bankData3', input3)
                  }
                  controlled
                />
              </div>
            )}
          </div>
          <div className='col-sm-9 pe-0'>
            <Input
              id='bank_type_data_input_3'
              type='text'
              controlled
              value={bankDetailsData3}
              onChange={(e) =>
                setHandler('bankDetailsData3', 'bankData3', e.target.value)
              }
              onBlur={() =>
                setInput3Error(
                  shouldShowError(
                    bankDetailsType3,
                    bankDetailsData3,
                    'bankData3'
                  )
                )
              }
              disabled={shouldBeDisabled(selectOption3, input3)}
              hasError={input3Error}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(BankData);
