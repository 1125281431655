import RequestAdapter from '../adapters/RequestAdapter';
import { API_V3_PATH_BASE } from '../constants';
import { ENDPOINTS } from '../enums/endpoints';

/**
 * Export SAF-T file
 *
 * @function
 *
 * @param {object} params
 *
 * @returns {object} JSON response
 */
const exportSAFT = async (params) => {
  console.log(params);

  const requestConfig = {
    url: `${API_V3_PATH_BASE}${ENDPOINTS.SAFT.EXPORT}?${params}`,
    method: 'GET',
  };

  return await RequestAdapter.request(requestConfig);
};

export const SAFTService = {
  exportSAFT,
};
