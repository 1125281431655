import React, { useEffect, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import SelectWithValues from '../../../../atoms/selects/SelectWithValues';
import TextArea from '../../../../atoms/textareas/TextArea';
import { GlobalsHelpers } from '../../../helper/globals';
import CheckBoxLabel from '../../../../molecules/labels/CheckBoxLabel';

export const BillingSection = (props) => {
  const {
    fields,
    isCreateContactPage,
    currencies,
    onChangeField,
    checkBoxState,
    onChangeCheckBox,
  } = props;
  const defaultValues = useMemo(() => {
    return {
      tax_exemption_code: GlobalsHelpers.getTaxExemptionList(props.intl)[0]
        .value,
      payment_mechanism: GlobalsHelpers.getPaymentMechanismList(props.intl)[0]
        .value,
      payment_days: GlobalsHelpers.getDueList(props.intl)[0].value,
      currency_id: 1,
      language: GlobalsHelpers.getLanguageList(props.intl)[0].value,
      document_copies: GlobalsHelpers.getNumberOfCopiesList(props.intl)[0]
        .value,
    };
  }, [props.intl]);

  useEffect(() => {
    if (!checkBoxState) {
      if (!isCreateContactPage) {
        Object.keys(fields).forEach((key) => {
          if (fields[key] == null && key in defaultValues) {
            onChangeField(key, defaultValues[key]);
          }
        });
      } else {
        Object.keys(defaultValues).forEach((key) => {
          if (fields[key] == null && key in defaultValues) {
            onChangeField(key, defaultValues[key]);
          }
        });
      }
    }
  }, [
    isCreateContactPage,
    fields,
    defaultValues,
    onChangeField,
    checkBoxState,
  ]);

  return (
    <div className='form-container'>
      <div className='form-row row'>
        <div className='col-12'>
          <CheckBoxLabel
            className='checkbox'
            onChange={onChangeCheckBox}
            checked={checkBoxState}
          >
            <span className='ms-2'>
              {props.intl.messages['billingDefaultSettings']}
            </span>
          </CheckBoxLabel>
        </div>
        <div className='col-md-6 col-sm-12 mt-3'>
          <label className='text-label'>
            {props.intl.messages['taxExemptionReason']} *
          </label>
          <SelectWithValues
            id='tax_id_select'
            key='tax_key_select'
            valueName='value'
            options={GlobalsHelpers.getTaxExemptionList(props.intl)}
            value={fields?.tax_exemption_code}
            onChange={(e) =>
              onChangeField('tax_exemption_code', e.target.value)
            }
            disabled={checkBoxState}
          />
        </div>

        <div className='col-md-6 col-sm-12 mt-3'>
          <label className='text-label'>
            {props.intl.messages['paymentMechanism']} *
          </label>
          <SelectWithValues
            id='payment_id_select'
            key='payment_key_select'
            valueName='value'
            options={GlobalsHelpers.getPaymentMechanismList(props.intl)}
            value={fields?.payment_mechanism}
            onChange={(e) => onChangeField('payment_mechanism', e.target.value)}
            disabled={checkBoxState}
          />
        </div>

        <div className='col-md-6 col-sm-12 mt-3'>
          <label className='text-label'>{props.intl.messages['due']} *</label>
          <SelectWithValues
            id='due_id_select'
            key='due_key_select'
            valueName='value'
            options={GlobalsHelpers.getDueList(props.intl)}
            value={fields?.payment_days}
            onChange={(e) => onChangeField('payment_days', e.target.value)}
            disabled={checkBoxState}
          />
        </div>

        <div className='col-md-6 col-sm-12 mt-3'>
          <label className='text-label'>
            {props.intl.messages['currency']} *
          </label>
          <SelectWithValues
            id='currency_id_select'
            key='currency_key_select'
            valueName='value'
            options={currencies}
            value={fields?.currency_id}
            onChange={(e) => onChangeField('currency_id', e.target.value)}
            disabled={checkBoxState}
          />
        </div>

        <div className='col-md-6 col-sm-12 mt-3'>
          <label className='text-label'>
            {props.intl.messages['language']} *
          </label>
          <SelectWithValues
            id='language_id_select'
            key='language_key_select'
            valueName='value'
            options={GlobalsHelpers.getLanguageList(props.intl)}
            value={fields?.language}
            onChange={(e) => onChangeField('language', e.target.value)}
            disabled={checkBoxState}
          />
        </div>

        <div className='col-md-6 col-sm-12 mt-3'>
          <label className='text-label'>
            {props.intl.messages['numberOfCopies']} *
          </label>
          <SelectWithValues
            id='document_copies_id_select'
            key='document_copies_key_select'
            valueName='value'
            options={GlobalsHelpers.getNumberOfCopiesList(props.intl)}
            value={fields?.document_copies}
            onChange={(e) => onChangeField('document_copies', e.target.value)}
            disabled={checkBoxState}
          />
        </div>

        <div className='col-sm-12 mt-3'>
          <label className='text-label'>
            {props.intl.messages['observations']}
          </label>

          <TextArea
            id='billing_observations'
            type='text'
            limit='100'
            defaultValue={fields?.observations}
            onChange={(e) => onChangeField('observations', e.target.value)}
            disabled={checkBoxState}
          />
        </div>
      </div>
    </div>
  );
};

export default injectIntl(BillingSection);
